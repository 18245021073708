import { Link } from "react-router-dom";
import Nav from "../components/Nav";

export default function Header({ darkModeOn, setDarkModeOn }) {
  return (
    <header className="py-4 px-8 flex justify-between content-center items-center bg-gradient-to-t from-transparent to-slate-700/40 dark:to-black dark:bg-slate-900">
      <Link to="/">
        <h1 className="font-black text-transparent bg-clip-text bg-gradient-to-t from-sky-800 to-black px-3 text-xl sm:text-2xl md:text-3xl dark:text-white">
          TYLER McMULLEN
        </h1>
      </Link>
      <Nav darkModeOn={darkModeOn} setDarkModeOn={setDarkModeOn} />
    </header>
  );
}
