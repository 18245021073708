import NavLinkButton from "./NavLinkButton";

export default function NavMenu() {
  return (
    <menu className="hidden md:flex">
      <NavLinkButton path="/" content="Home" />
      <NavLinkButton path="/projects" content="My Projects" />
    </menu>
  );
}
