import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Header from "./application/Header";
import Footer from "./application/Footer";
import Projects from "./routes/projects/Projects";

function App() {
  const [darkModeOn, setDarkModeOn] = useState(true);

  return (
    <div className={`${darkModeOn && "dark"} h-screen`}>
      <div className="App flex flex-col justify-between h-full font-sans dark:bg-slate-900">
        <Header darkModeOn={darkModeOn} setDarkModeOn={setDarkModeOn} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
