export default function LinkButton({ content, path }) {
  return (
    <>
      <a
        className="mt-2 mr-5 bg-blue-500 hover:scale-110 hover:bg-blue-700 text-white text-sm md:text-md py-1.5 px-3 rounded-full"
        href={path}
        target="_blank"
        rel="noreferrer"
      >
        {content}
      </a>
    </>
  );
}
