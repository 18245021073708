import gamersReclaimPhoto from "../images/gamers_reclaim_screenshot.png";
import fitTrakPhoto from "../images/FitTrakImg.png";
import strangersThingsPhoto from "../images/StrangersThingsImage.png";
import GalaxyPhoto from "../images/GalaxyImage.png";

export const projects = [
  {
    title: "Galaxy Progress Tracker",
    description: `Galaxy Progress Tracker is a fullstack web application that I developed as a simple way to organize and track graded assignments for elementary school educators. With simplicity in mind, users can add students to their roster for the current academic year, add new assignments, and add graded assignments including digital copies as image files/PDFs of the documents, which can be easily accessed and downloaded as needed. Given that assignments are attached to both a student and a specifically assigned activity, Galaxy Progress Tracker allows for easy searching and viewing of graded assignments for a specific student or for a specific assignment. This structure is less limiting than using file storage systems, which would require a rigid file structure or strict file naming practice in order to effectively organize graded assignments.`,
    technologiesUsed: [
      "JavaScript",
      "Node.js",
      "Express.js",
      "PostgreSQL",
      "React.js",
      "AWS",
      "TailwindCSS",
    ],
    deployedSiteLink: "https://funny-boba-e1553b.netlify.app/",
    sourceCodeLink: "https://github.com/mctyles/galaxy_progress_tracker",
    imageFile: GalaxyPhoto,
    imageAlt: "Photo of Galaxy Progress Tracker Student Assignment Page.",
  },
  {
    title: "Gamer's Reclaim",
    description: `An eCommerce website designed to sell refurbished gaming accessories at an affordable price. Collaborated with two developers to plan and create application. Developed the backend in collaboration with team through pair programming and implemented the React components for the cart, admin functionality, and viewing and filtering of available products.`,
    technologiesUsed: [
      "JavaScript",
      "Node.js",
      "Express.js",
      "PostgreSQL",
      "React.js",
    ],
    deployedSiteLink: "https://gamers-reclaim.onrender.com/",
    sourceCodeLink: "https://github.com/Cosmic-dust-force/gamers_reclaim/",
    imageFile: gamersReclaimPhoto,
    imageAlt: "Photo of Gamer's Reclaim app homepage.",
  },
  {
    title: "Fit Trak",
    description: `Fitness tracker app that allows users to create routines with specific exercise activities.
    Users can track and create their own routines, add and edit activities, and view community routines.
    Developed frontend components of Single Page Application using React and ReactRouter resulting in an easy-to-use experience for the user.
    Constructed backend including database and API.`,
    technologiesUsed: [
      "JavaScript",
      "Node.js",
      "Express.js",
      "PostgreSQL",
      "React.js",
      "Restful APIs",
    ],
    deployedSiteLink: "https://quiet-douhua-724a8c.netlify.app/",
    sourceCodeLink: "https://github.com/mctyles/FitnessTrackr",
    imageFile: fitTrakPhoto,
    imageAlt:
      "Screenshot of FitTrak application showing window when editing activities.",
  },
  {
    title: "Stranger's Things",
    description: `Craigslist-like application in which users can create and search posts and message other users.
    Application allows users to track their posts, search active posts, and interact with other users in order to advertise used items for sale.
    Developed frontend components using React and ReactRouter displaying data from API.`,
    technologiesUsed: [
      "React.js",
      "JavaScript",
      "CSS",
      "Node.js",
      "RESTful APIs",
    ],
    deployedSiteLink: "https://thunderous-paprenjak-beaa62.netlify.app/",
    sourceCodeLink: "https://github.com/mctyles/Strangers_Things_Project",
    imageFile: strangersThingsPhoto,
    imageAlt: "Photo of Stranger's Things app.",
  },
];

export const badgeImageUrls = {
  "React.js":
    "https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB",
  JavaScript:
    "https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black",
  TailwindCSS:
    "https://img.shields.io/badge/Tailwind_CSS-38B2AC?style=for-the-badge&logo=tailwind-css&logoColor=white",
  "Express.js":
    "https://img.shields.io/badge/Express.js-404D59?style=for-the-badge",
  PostgreSQL:
    "https://img.shields.io/badge/PostgreSQL-316192?style=for-the-badge&logo=postgresql&logoColor=white",
  AWS: "https://img.shields.io/badge/Amazon_AWS-232F3E?style=for-the-badge&logo=amazon-aws&logoColor=white",
  CSS: "https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white",
  "Node.js":
    "https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white",
};
