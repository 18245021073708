import FooterSocialLink from "../components/FooterSocialLink";
import githubLogo from "../images/github-mark.svg";
import linkedinLogo from "../images/linkedin-tile.svg";
import emailEnvelope from "../images/email-envelope.svg";

export default function Footer() {
  return (
    <footer className="flex justify-center bottom-0 sticky p-2 w-full bg-slate-500 border-t-[1px] dark:bg-slate-800">
      <FooterSocialLink
        link="https://github.com/mctyles"
        iconImg={githubLogo}
        imgAlt="GitHub Logo"
      />
      <FooterSocialLink
        link="https://www.linkedin.com/in/tyler-mcmullen/"
        iconImg={linkedinLogo}
        imgAlt="LinkedIn Logo"
      />
      <FooterSocialLink
        link="mailto:tylermcmu@gmail.com"
        iconImg={emailEnvelope}
        imgAlt="Email Symbol. Click to send me an email."
      />
    </footer>
  );
}
