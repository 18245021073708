export default function FooterSocialLink({ link, iconImg, imageAlt }) {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <img
        src={iconImg}
        alt={imageAlt}
        className="rounded mx-1 w-14 p-2 hover:relative hover:bottom-1.5"
      />
    </a>
  );
}
