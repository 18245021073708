import tylerPortrait from "../images/tylerportrait.png";

export default function Home() {
  return (
    <main className="text-center flex flex-col items-center justify-center w-100 px-12 py-10 sm:px-24 md:px-36 dark:bg-slate-900">
      <h1 className="animate-fadein text-lg font-extrabold text-transparent bg-clip-text bg-gradient-to-t from-sky-800/75 to-sky-900 sm:text-xl md:text-2xl dark:text-white">
        Hello, my name is Tyler!
      </h1>
      <img
        className="animate-fadein max-w-xs w-2/3 sm:w-2/3 md:w-3/4 my-10 shadow-2xl rounded-full dark:shadow-white dark:shadow-xl"
        src={tylerPortrait}
        alt="Portrait of Tyler"
      ></img>
      <section className="mx-3 sm:mx-6 md:mx-8 text-sky-900">
        <p className="animate-fadein text-md sm:text-lg md:text-xl m-6 dark:text-white">
          I am a web developer with an affinity for creating and learning
          continuously. I love to solve problems and to explore new technologies
          in order to build web applications that aim to improve quality of life
          for our local community and beyond.
        </p>
        <p className="animate-fadein mx-6 text-md sm:text-lg md:text-xl dark:text-white">
          When I am not coding, you can find me summiting hills, jumping in the
          ocean, taking photos of local wildlife, growing peppers, and working
          on my '94 Miata.
        </p>
        <hr className="animate-fadein border-0 h-0.5 w-48 rounded mx-auto m-6 bg-slate-900/50 dark:bg-white" />
        <p className="animate-fadein my-3 mx-6 text-md sm:text-lg md:text-xl dark:text-white">
          If you would like to get in touch, please do not hesitate to contact
          me using the links below!
        </p>
      </section>
    </main>
  );
}
