import { useState } from "react";
import NavMenu from "./NavMenu";
import Hamburger from "./Hamburger";
import MobileNavMenu from "./MobileNavMenu";
import DarkModeToggle from "./DarkModeToggle";

export default function Nav({ darkModeOn, setDarkModeOn }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  function handleHamburgerClick() {
    setIsNavOpen((prev) => !prev);
  }

  return (
    <nav className="flex items-center">
      <NavMenu />
      <DarkModeToggle darkModeOn={darkModeOn} setDarkModeOn={setDarkModeOn} />
      <Hamburger clickHandler={handleHamburgerClick} />
      {isNavOpen && <MobileNavMenu clickHandler={handleHamburgerClick} />}
    </nav>
  );
}
