import { Link } from "react-router-dom";

export default function NavLinkButton({ path, content }) {
  return (
    <li className="mr-4 my-8 md:my-0">
      <Link
        className="bg-transparent hover:bg-gradient-to-t hover:from-blue-700 hover:to-blue-900 hover:text-white text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded-full dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-500 dark:hover:text-white"
        to={path}
      >
        {content}
      </Link>
    </li>
  );
}
