import LinkButton from "../../components/LinkButton";
import { badgeImageUrls } from "../../data/project-data";

export default function Project({
  project: {
    title,
    description,
    technologiesUsed,
    deployedSiteLink,
    sourceCodeLink,
    imageFile,
    imageAlt,
  },
}) {
  return (
    <section className="m-5 p-10 flex flex-col items-start border border-slate-600/50 rounded shadow-lg sm:m-10 dark:bg-slate-700/50 dark:shadow-gray-500">
      <h2 className="animate-fadein text-xl sm:text-2xl font-semibold uppercase pb-2 dark:text-slate-200">
        {title}
      </h2>
      <div className="flex flex-col items-center lg:flex-row dark:text-white">
        <div className="flex flex-col">
          <p className="animate-fadein py-3 text-left text-sm sm:text-md md:text-lg">
            {description}
          </p>
          <section className="animate-fadein py-3 flex flex-wrap content-center justify-center m-auto lg:m-0 lg:justify-start">
            {technologiesUsed.map(
              (technology) =>
                badgeImageUrls[technology] && (
                  <img
                    className="w-auto h-auto max-w-[50px] mr-1 mb-1 rounded border border-slate-300/50 sm:max-w-none"
                    src={badgeImageUrls[technology]}
                    alt={technology}
                  />
                )
            )}
          </section>
        </div>
        <img
          src={imageFile}
          alt={imageAlt}
          className="animate-fadein max-w-lg w-5/6 h-auto p-3 m-6 lg:my-0 lg:ml-12 border rounded hover:border hover:border-indigo-500/75 hover:scale-110 shadow-2xl lg:self-center dark:bg-white dark:shadow-gray-400"
        />
      </div>
      <div className="animate-fadein mt-6 pl-5 pb-2 flex flex-col self-center justify-between lg:self-start sm:flex-row">
        <LinkButton content="View Deployed Site" path={deployedSiteLink} />
        {sourceCodeLink && (
          <LinkButton content="View Source Code" path={sourceCodeLink} />
        )}
      </div>
    </section>
  );
}
