import { projects } from "../../data/project-data";
import Project from "./Project";

export default function Projects() {
  return (
    <main className="py-8 dark:bg-slate-900">
      <h1 className="text-md text-center sm:text-lg md:text-xl font-semibold uppercase dark:text-white">
        My Projects
      </h1>
      {projects.map((project) => (
        <Project key={project.title} project={project} />
      ))}
    </main>
  );
}
